import React from "react"
import { Link, graphql } from "gatsby"

import Img from 'gatsby-image';

class CallToActionButton extends React.Component {

  render() {
    var onDevice = (typeof window !== 'undefined');
    var iOS = (typeof window !== 'undefined') && /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    var android = (typeof window !== 'undefined') && /(android)/i.test(navigator.userAgent);
    var chrome = (typeof window !== 'undefined') && !!(window as any).chrome;

    return (
      <div className="buttons" style={this.props.style}>
        <a data-hypertag="Non-Chrome Hero" style={{ border: this.props.inverted ? "3px #fff solid" : "3px #000 solid" }} href="/pricing/" className="button primary">Get Started</a>
        {/* {!onDevice && <div style={{ height: "72px", border: "0" }} className="button"></div>}
        {onDevice && (!iOS && !android) && (<a data-hypertag="Non-Chrome Hero" style={{ border: this.props.inverted ? "3px #fff solid" : "3px #000 solid" }} href="/app/" className="button primary">Get Started for Free</a>)}
        {onDevice && chrome && (!iOS && !android) && (<a id="chrome-hero" data-hypertag="Chrome Extension Hero" style={{
          border: "0",
          backgroundColor: this.props.inverted ? "#000" : "#fff",
          fontWeight: this.props.inverted ? "400" : "500",
          marginLeft: this.props.inverted ? "0" : "16px",
          color: this.props.inverted ? "rgba(255, 255, 255, .8)" : "#000"
        }} href="https://chrome.google.com/webstore/detail/hyperlink/dlgncnofjdagniahkcimljhineapppbi" className="button">Get Chrome Extension</a>)}
        {iOS && (<a data-hypertag="iOS Hero" href="https://apps.apple.com/us/app/id1480418373?ls=1"><img className="device-button apple" src="https://usehyperlink.com/images/download.svg" /></a>)}
        {(android && !iOS) && (<a data-hypertag="Android Hero" className="device-button" href="https://play.google.com/store/apps/details?id=app.siggi.android.link"><img className="device-button apple" src="https://usehyperlink.com/images/download_play.png" /></a>)} */}
      </div>
    )
  }
}

export default CallToActionButton