import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Push from "../components/push"
import FakeFriendSection from "../components/fake_friend_section"
import CallToActionSection from "../components/call_to_action_section"
import Img from 'gatsby-image';
import CallToActionButton from "../components/call_to_action_button"

class Home extends React.Component {
  scrollDown() {
    var sections = document.getElementsByClassName("section");
    if (!sections || sections.length < 2) {
      return;
    }
    const y = sections[1].getBoundingClientRect().top + window.pageYOffset - 64;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Find out who your fake friends are" description="Hyperlink sends you push notifications when your friends click on the links you send them." slug="/fake-friends/" image="https://usehyperlink.com/images/caught.png" />
        {/* <div className="section above-the-fold">
          <div className="container">
            <Push linkName="iMessage Link" />
            <div className="hero">
              <h2>Are your friends<br />clicking?</h2>
              <div className="subtitle">Get push notifications when your friends click on the links you send them.</div>
              <CallToActionButton />

              <div onClick={this.scrollDown.bind(this)} className="material-icons down-arrow">keyboard_arrow_down</div>
            </div>
          </div>
        </div> */}
        <FakeFriendSection />
        <CallToActionSection title="Find your real friends today." />
      </Layout>
    )
  }
}

export default Home

export const pageQuery = graphql`
  query {
          site {
          siteMetadata {
          title
        }
        }
    playImage: file(relativePath: {eq: "assets/download_play.png" }) {
          childImageSharp {
          fluid(maxWidth: 520) {
          ...GatsbyImageSharpFluid
        }
        }
      }
    allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC }) {
          edges {
          node {
          excerpt
          fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
        title
        description
      }
    }
  }
}
}
`
