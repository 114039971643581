import React from "react"
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import CallToActionButton from "./call_to_action_button";

function CallToActionSection({ title }) {
  return (
    <div className="section section-flex-height" style={{ backgroundColor: "#000", color: "#fff", padding: "96px 0", textAlign: "center" }}>
      <div className="container cta-section">
        <h2>{title || "Try Hyperlink today."}</h2>
        <CallToActionButton inverted={true} style={{ margin: "32px auto 0 auto", maxWidth: "300px" }} />
      </div>
    </div>
  );
}

export default CallToActionSection;